<template>
  <div>
    <div class="kiosk-footer-height" />
    <b-row
      v-if="showSupport"
      class="kiosk-footer align-items-center text-light"
    >

      <b-col
        cols="12"
        class="d-flex justify-content-center align-items-center "
      >
        <img
          :src="qrCode"
          class="qrcode-img"
        >

      </b-col>
      <b-col

        cols="12"
        style="margin-top: -0.7cm;"
        class="d-flex justify-content-end"
        @click="showSupport = !showSupport"
      >
        <FeatherIcon
          icon="ArrowLeftCircleIcon"
          size="36"
          class="font-bold"
          style=""
        />
      </b-col>

      <b-col
        cols="12"
        class="mb-2"
        style="margin-top: -0.1cm;"
      >

        <h4 class="m-0 p-0 text-light text-center">
          IT Support:
        </h4>
        <h4 class="m-0 p-0 text-light text-center">
          <a
            href="https://t.me/ITSupportJSC"
            class="text-light"
          >https://t.me/ITSupportJSC</a>
        </h4>
      </b-col>

    </b-row>

    <b-row class="kiosk-footer align-items-center" style="background: none;" v-else>
      <b-button
        variant="primary"
        class="btn-icon"
         @click="showSupport = !showSupport"
      >
        <feather-icon
          icon="HelpCircleIcon"
          size="36"
        />
      </b-button>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    kiosk: {
      type: Object,
      default: () => ({
        isKiosk: false,
      }),
    },
  },
  data() {
    return {
      qrCode: require('@/assets/images/kiosk/ITSupportJSC.png'),
      showSupport: false,
    }
  },
}
</script>

<style lang="scss" scoped>
$kiosk-footer-height: 140px;

.kiosk-footer-height {
  height: $kiosk-footer-height;
}

.kiosk-footer {
  position: fixed;
  bottom: 0;
  left: 2;
  width: 33%;
  padding: 1rem;
  height: $kiosk-footer-height;
  background: linear-gradient(135deg, rgba(255, 107, 69, 0.95) 0%, rgba(255, 211, 0, 0.95) 100%);
}

.qrcode-img {
  max-width: 5rem;
  height: auto;
}
</style>
