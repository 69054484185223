<template>
  <b-navbar
    sticky
    fill
    class="justify-content-between bg-kiosk"
    style="height: 6rem; width: 100%"
  >
    <b-navbar-brand align="start">
      <!-- <font-awesome-icon v-if="companyImage === 'default' " icon="building" size="6x" class="mt-10" :style="{ color: 'white' }" /> -->
      <router-link :to="{ name: 'home' }">
        <div style="height: 4rem">
          <img
            :src="latestCompanyImage"
            class="w-auto h-100 object-scale-down"
          >
        </div>
      </router-link>
    </b-navbar-brand>
    <b-navbar-nav
      v-if="!kiosk.isKiosk"
      class="ml-auto"
      align="end"
    >
      <div
        v-if="isLoggedIn"
        class="w-100"
      >
        <b-button
          to="/"
          class="w-100 shadow-lg"
          variant="primary"
        >{{
          $store.state.auth.ActiveUser.account_type === this.$AccountTypes.APPLICANT
            ? 'Manage My Application'
            : 'Dashboard'
        }}</b-button>
      </div>

      <div
        v-else
        class="w-100"
      >
        <b-button
          class="w-full shadow-lg"
          variant="primary"
          @click="activateLogin"
        >
          Sign In
        </b-button>
      </div>
    </b-navbar-nav>
    <div class="mr-2">
      <b-button
        variant="primary"
        class="btn-icon"
        style="position: relative;"
        @click="shareJobModal = true"
      >
        <FeatherIcon
          icon="ShoppingCartIcon"
          size="24"
          style="color: white;"
        />
        <span
          style="
            position: absolute;
            top: 0;
            right: 0;
            background-color: whitesmoke;
            color: #f37044;
            border-radius: 50%;
            padding: 2px 6px;
            font-size: 14px;
            font-weight: bold;
            margin-top: -10px;
            margin-right: -10px;
        "
        >
          {{ $store.state.auth.cart_jobs }}
        </span>
      </b-button>
    </div>
    <router-view
      @image-update="updateCompanyImage"
      @login-state="updateUserLoggedIn"
      @update-cart="updateCartCounter"
    />
    <b-modal
      v-model="login_form"
      centered
      size="lg"
      hide-footer
      title=""
    >
      <b-row cols="12">
        <h6 class="mb-6 ml-1">
          You will require a Headhunters HQ account to proceed with the
          application.
        </h6>
      </b-row>

      <b-row cols="12">
        <b-tabs
          v-model="active_tab"
          align="center"
          class="w-100"
          fill
        >
          <b-tab
            title="Login"
            class="w-100"
          >
            <LoginForm
              class="w-100"
              which-page="listings"
              :event-id="this.$router.currentRoute.path"
              @user-logged-in="afterUserLoggedIn"
            />
          </b-tab>
          <b-tab
            title="Registration"
            class="w-100"
          >
            <RegistrationForm
              class="w-100"
              which-page="listings"
              account-type="Applicant"
              @user-registered="afterUserLoggedIn"
            />
          </b-tab>
        </b-tabs>
      </b-row>
    </b-modal>

    <b-modal
      id="modal-lg"
      ref="first-share-popup"
      v-model="shareJobModal"
      ok-title="Share & Apply Later"
      :ok-disabled="getLSJobs().length === 0"
      ok-variant="outline-secondary"
      cancel-title="Continue Search"
      cancel-variant="success"
      size="xl"
      :title="`Selected Job${$length(jobList) > 1 ? 's' : ''}`"
      @ok="proceedFunc"
      @cancel="cancelFunc"
    >
      <div v-if="getLSJobs().length !== 0">
        <div>
          <h6>Roles you have selected to share and apply later:</h6>
          <ul
            class="overflow-auto-vertical"
            style="max-height: 20cm;"
          >
            <hr>
            <li
              v-for="(item, index) in getLSJobs()"
              :key="index"
              class="mt-1"
            >
              <b-row>
                <b-col
                  cols="10"
                  class="job-item"
                >

                  <div class="d-flex flex-gap-xs align-items-center mb-1">
                    <font-awesome-icon
                      icon="building"
                      class="text-secondary"
                      style="font-size: 16px;"
                    />
                    <span>{{ item.company }}</span>
                  </div>
                  <div class="d-flex align-items-center">
                    <h5><strong>{{ item.position }}</strong></h5>
                  </div>

                  <div class="d-flex">

                    <div class="d-flex flex-gap-xs justify-content-between align-items-center mr-1">
                      <FeatherIcon
                        icon="MonitorIcon"
                        size="16"
                        style="font-weight: bold!important;stroke-width: 3;"
                      />
                      <span>{{ item.interview_type }}</span>
                    </div>

                    <div class="d-flex flex-gap-xs justify-content-between align-items-center">
                      <font-awesome-icon
                        icon="map-marker-alt"
                        class=""
                        style="font-size: 16px;"
                      />
                      <span>{{ item.location.replace('()', '') }}</span>
                    </div>

                    <div class="d-flex flex-gap-xs justify-content-between align-items-center mx-1">
                      <font-awesome-icon
                        icon="dollar-sign"
                        style="font-size: 16px;"
                      />
                      <span>{{ item.salary }}</span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="1">
                  <b-button
                    variant="primary"
                    class="btn-icon mt-2"
                    @click="removeJob(item.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      size="28"
                    />
                  </b-button>
                </b-col>
              </b-row>

              <hr class="mb-1">
            </li>

          </ul>

        </div>
        <p>You can share more than 1 job at once. Would you like to continue to search for more jobs?</p>
      </div>
      <div v-else>
        <h6>You have not yet added any jobs to your cart</h6>
      </div>

    </b-modal>

  </b-navbar>
</template>

<script>
import {
  BButton,
  BNavbar,
  BModal,
  BNavbarNav,
  BNavbarBrand,
  BTabs,
  BTab,
  BRow,
} from 'bootstrap-vue'

import { isUserLoggedIn } from '@/auth/utils'
import { listingsMixin } from '@/views/event-management-system/mixins/listingsMixins'
import LoginForm from '@/views/authentication/login/LoginForm.vue'
import RegistrationForm from '@/views/authentication/registration/RegistrationForm.vue'

export default {
  components: {
    LoginForm,
    RegistrationForm,

    BNavbarNav,
    BButton,
    BModal,
    BNavbar,
    BNavbarBrand,
    BTabs,
    BTab,
    BRow,
  },
  props: {
    companyImage: {
      type: String,
      default: '',
    },
    kiosk: {
      type: Object,
      default: () => ({
        isKiosk: false,
      }),
    },
  },
  data() {
    return {
      isLoggedIn: false,
      login_form: false,
      active_tab: 0,
      footer_class: 'w-full',
      latestCompanyImage: 'not-set',
      special_styling: '',
      accountancy_event: false,
      totalJobs: 0,
      isUserLoggedIn,
      jobList: '',
      shareJobModal: false,
    }
  },
  watch: {
    companyImage(newLink) {
      // this.latestCompanyImage = newLink
      if (this.$isNotEmpty(this.kiosk) && this.kiosk.isKiosk) {
        this.latestCompanyImage = this.kiosk.logos[this.kiosk.companyName]
      } else {
        this.$generateDownloadUrl(newLink)
          .then(res => {
            this.latestCompanyImage = res
          })
      }
    },
    '$store.state.auth.ActiveUser': function (newVal) {
      this.isLoggedIn = true
    },
    'kiosk.isKiosk': async function () {
      if (this.$isNotEmpty(this.kiosk) && this.kiosk.isKiosk) {
        this.latestCompanyImage = this.kiosk.logos[this.kiosk.companyName]
      } else {
        this.latestCompanyImage = this.kiosk.logos.hhq
        // this.latestCompanyImage = await this.$generateDownloadUrl('media/hq_logo.png')
      }
    },
  },

  async created() {
    this.isLoggedIn = isUserLoggedIn()
    const shareJobList = JSON.parse(localStorage.getItem('share_job_list')) || {}
    this.$store.commit('auth/UPDATE_CART_JOBS', Object.keys(shareJobList).length, { root: true })
    window.addEventListener('storage', this.handleStorageChange)
    this.latestCompanyImage = this.companyImage
    if (this.$router.currentRoute.path === '/listings') {
      this.latestCompanyImage = await this.$generateDownloadUrl('media/hq_logo.png')
    } else if (this.$route.name === 'company-listings-page') {
      this.latestCompanyImage = await this.$generateDownloadUrl('media/hq_logo.png')
    } else if (this.$isNotEmpty(this.kiosk) && this.kiosk.isKiosk) {
      this.$set(
        this,
        'latestCompanyImage',
        this.kiosk.logos[this.kiosk.companyName],
      )
    }
    let { event_id } = this.$route.params
    if (
      this.$route.params.company
      && this.$route.params.company.endsWith('_g')
    ) {
      event_id = this.$route.params.company.replace('_g', '')
      if (event_id === '61dcd0dc62adac05c99714c4') {
        this.accountancy_event = true
      }
    } else if (event_id === '61dcd0dc62adac05c99714c4') {
      this.accountancy_event = true
    }
  },
  methods: {
    removeJob(jobID) {
      const shareJobList = JSON.parse(localStorage.getItem('share_job_list')) || {}
      delete shareJobList[jobID]
      localStorage.setItem('share_job_list', JSON.stringify(shareJobList))
      this.$store.commit('auth/UPDATE_CART_JOBS', Object.keys(shareJobList).length, { root: true })
      this.$store.commit('auth/UPDATE_SHARE_JOB_LIST', shareJobList, { root: true })
    },
    proceedFunc() {
      const shareJobList = JSON.parse(localStorage.getItem('share_job_list')) || {}
      let firstValue = {}

      if (Array.isArray(shareJobList)) {
        firstValue = shareJobList[0] || {}
      } else if (typeof shareJobList === 'object' && shareJobList !== null) {
        firstValue = Object.values(shareJobList)[0] || {}
      }
      this.$emit('next-step', firstValue)
    },
    cancelFunc() {
      const currentPage = this.$route.name
      const startLocation = this.$router.history._startLocation
      if (currentPage === 'kiosk-page-event') {
        this.$router.push({ path: startLocation })
        if (startLocation.includes('/kiosk/job/')) {
          this.$router.push({ path: '/kiosk' })
        }
      }
    },
    getLSJobs() {
      const shareJobList = JSON.parse(localStorage.getItem('share_job_list')) || null
      let jobDetails = []
      if (shareJobList) {
        jobDetails = Object.values(shareJobList)
      }

      return jobDetails
    },
    handleStorageChange(event) {
      if (event.key === 'share_job_list') {
        this.totalJobs = JSON.parse(localStorage.getItem('share_job_list')) || null
        if (this.totalJobs) {
          this.totalJobs = this.totalJobs.length
        } else {
          this.totalJobs = 0
        }
      }
    },
    afterUserLoggedIn() {
      this.isLoggedIn = true
      this.login_form = false
    },

    updateCartCounter(input = null) {
      this.totalJobs = JSON.parse(localStorage.getItem('share_job_list')) || null

      if (this.totalJobs) {
        this.totalJobs = this.totalJobs.length
      } else {
        this.totalJobs = 0
      }
    },
    async updateCompanyImage(image) {
      if (image === 'default') {
        this.latestCompanyImage = await this.$generateDownloadUrl('media/hq_logo.png')
      } else {
        this.latestCompanyImage = image
      }
    },
    activateLogin() {
      if (isUserLoggedIn()) {
        this.isLoggedIn = true
        this.login_form = false
      } else {
        this.login_form = true
      }
    },

    updateUserLoggedIn() {
      this.isLoggedIn = isUserLoggedIn()
    },
  },
}
</script>
<style>
#app>div>header {
  background-color: #10163a;
}
.bg-kiosk{
  background: linear-gradient(135deg, rgba(255, 107, 69, 0.95) 0%, rgba(255, 211, 0, 0.95) 100%);
}
.overflow-auto-vertical {
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}
</style>
